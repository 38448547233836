// import FrontPage from "app/components/front-page";
import dynamic from "next/dynamic";
import Loading from "app/components/Loading";

const DynamicComponent = dynamic(() => import("app/components/front-page"), {
  loading: () => <Loading />,
  ssr: false,
});

export default function Home() {
  return <DynamicComponent />;
}
