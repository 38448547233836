import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPalette } from "@fortawesome/pro-solid-svg-icons";

export default function Loading() {
  return (
    <div
      className={"flex items-center justify-center min-h-screen text-slate-500"}
    >
      <FontAwesomeIcon
        className={"animate-spin"}
        icon={faPalette}
        size={"xl"}
      />
    </div>
  );
}
